import { STUDY_PROGRESS_TYPES } from '@/core/plugins/constants';

export const tableMixin = {
  data() {
    return {
      column: [
        {
          key: 'name',
          label: 'Khách hàng',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'schedule',
          label: 'Lịch trình',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'book11',
          label: 'Buổi hẹn 1 - 1 tiếp theo',
          sortable: false,
        },
        {
          key: 'book1n',
          label: 'Buổi hẹn 1 - n tiếp theo',
          sortable: false,
        },
        {
          key: 'lastActiveDate',
          label: 'Nhập app',
          sortable: true,
        },
        {
          key: 'studyProgress',
          label: 'Tiến độ học',
          sortable: true,
        },
        {
          key: 'needSupport',
          label: 'Ưu tiên hỗ trợ',
          sortable: true,
        },
      ],

      supportType: {
        Normal: 1,
        Priority: 2,
      },
    };
  },

  methods: {
    onClickAction(item = {}, type) {
      switch (type) {
        case 'UserDashboard':
          return this.goToUserDashboard(item);
        case 'UserApp':
          return this.goToUserApp(item);
        case 'Implement11':
          return this.goTo11Appt(item);
        case 'Book11':
          return this.openModalCalendar('coach11', item);
        case 'Implement1N':
          return this.goTo1NAppt(item);
        case 'Book1N':
          return this.openModalCalendar('coach1N', item);

        default:
          return this.goToUserDashboard(item);
      }
    },

    goToUserDashboard(item) {
      this.$router.push({
        name: 'user_dashboard',
        params: { id: item.patientId },
      });
    },

    goToUserApp(item) {
      this.$router.push({
        name: 'user_app_view',
        params: { id: item.patientId },
      });
    },

    goTo11Appt(item) {
      if (item?.coach11NextDating?.meetingLink) {
        window.open(item.coach11NextDating.meetingLink, '_blank');
      }
    },

    goTo1NAppt(item) {
      if (item?.coach1NNextDating?.meetingLink) {
        window.open(item.coach1NNextDating.meetingLink, '_blank');
      }
    },

    getActivedDate(date) {
      if (date < 0) return 'Chưa đăng nhập';
      const activeDate = this.$moment(date * 1000);
      if (!activeDate.isValid()) return '';

      const diffDays = activeDate.diff(this.$moment(), 'days');

      if (diffDays < -100) return '-100 ngày';

      if (diffDays < 0) return `${diffDays} ngày`;
      return 'Hôm nay';
    },

    getSupportTypeName(type) {
      return this.isSupportPrority(type) ? 'Ưu tiên' : 'Bình thường';
    },

    isSupportPrority(type) {
      return type === this.supportType.Priority;
    },

    getStudyProgress(type) {
      switch (type) {
        case STUDY_PROGRESS_TYPES.NEED_IMPROVE:
          return 'Cần cải thiện';
        case STUDY_PROGRESS_TYPES.IMPROVING:
          return 'Có tiến bộ';
        case STUDY_PROGRESS_TYPES.RIGHT_WAY:
          return 'Đúng lộ trình';
        case STUDY_PROGRESS_TYPES.GOOD:
          return 'Tốt';
        case STUDY_PROGRESS_TYPES.VERY_GOOD:
          return 'Rất tốt';
        case STUDY_PROGRESS_TYPES.PENDING:
          return 'Ngưng học';

        default:
          return '';
      }
    },

    getStudyProgressStyle(type) {
      switch (type) {
        case STUDY_PROGRESS_TYPES.NEED_IMPROVE:
          return 'study--improve';
        case STUDY_PROGRESS_TYPES.IMPROVING:
          return 'study--improving';
        case STUDY_PROGRESS_TYPES.RIGHT_WAY:
          return 'study--right';
        case STUDY_PROGRESS_TYPES.GOOD:
          return 'study--good';
        case STUDY_PROGRESS_TYPES.VERY_GOOD:
          return 'study--perfect';
        case STUDY_PROGRESS_TYPES.PENDING:
          return 'study--pending';

        default:
          return 'study--improve';
      }
    },
  },
};
